import React from "react"
import PrivacyPolicyTemplate from "../../../components/PrivacyPolicyTemplate"

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyTemplate
      name="Suivi Glaucome Pr Renard"
      mail="scm.breteuil@orange-business.fr"
    />
  )
}

export default PrivacyPolicy
